import { onDevEnv, Client } from "kiwi-bundle"
import { DROPinAPI } from "dropin-recipes"
import router from "./router"
import "./main.scss"

/* onDevEnv(() => {
  DROPinAPI.enableLocalMode(8040)
}) */

;(DROPinAPI as any).URL = "https://api.lacloche.blueforest.cc"

const CLIENT = new Client(router)

export default CLIENT
