import { React } from "kiwi-bundle"
import { GoogleMapsDocumentOptionsStyle } from "dropin-recipes"
import { GoogleMap } from "react-google-maps"
import { WebComponent } from "kiwi-bundle"
import { linesStore } from "../stores/lines"
import "./GoogleMapsPrintable.scss"

const formatDate = (str: string): string => {
  const date = new Date(str)
  return ("0" + date.getDate()).slice(-2) + "/"
    + ("0" + (date.getMonth() + 1)).slice(-2) + "/"
    + date.getFullYear()
}

export const hardcodedLaClocheHtml = (line: any, printMode: boolean, name?: string) => {
  return <div style={{ fontSize: 14, maxWidth: "375px", }} key={line.id}>
    <div style={{ fontSize: 17, fontWeight: "bold", }}>
      {typeof name !== "undefined" && `[${name}] ` || ""}{line.name}
    </div>
    {line.address && (
      <p style={{ margin: "10px 0 0 0", }}>
        <a href={`https://google.com/maps/dir//${line.gps.lat},${line.gps.lng}`} target="_blank">
          {line.address}
        </a>
      </p>
    )}
    {line.type && (
      <p style={{ margin: "10px 0 0 0", }}>Type : {line.type}</p>
    )}
    {line.time_services && <p style={{ margin: "10px 0 0 0", }}>Horaires : {line.time_services}</p>}
    {line.access_time && <p style={{ margin: "10px 0 0 0", }}>Horaires : {line.access_time}</p>}
    {line.activity_time && <p style={{ margin: "10px 0 0 0", }}>Horaires : {line.activity_time}</p>}
    {(() => {
      if(line.start_date) {
        if(line.end_date) {
          if(line.start_date === line.end_date) {
            return <p style={{ margin: "10px 0 0 0", }}>{"Le " + formatDate(line.start_date)}</p>
          }
          return <p style={{ margin: "10px 0 0 0", }}>
            {"Du " + formatDate(line.start_date) + " au " + formatDate(line.end_date)}
          </p>
        }
        return <p style={{ margin: "10px 0 0 0", }}>{"A partir du " + formatDate(line.start_date)}</p>
      }
      if(line.end_date) {
        return <p style={{ margin: "10px 0 0 0", }}>{"Jusqu'au " + formatDate(line.start_date)}</p>
      }
      return <div></div>
    })()}
    {/*line.transports && (() => {
      const transports = Object.keys(line.transports)
      if(transports.length > 0) {
        return <p style={{ margin: "16px 0 4px", }}>
          Transports à proximité : {transports.map((transport, index) => <span
            key={`${line.id}-t${index}`}
            style={{
              backgroundColor: line.transports[transport].color,
              margin: "0 3px",
              padding: "3px",
              borderRadius: "2px",
            }}
            children={transport}
          />)}
        </p>
      }
    })()*/}

    {line.services && (<div style={{ margin: "10px 0 0 0", display: "flex" }}>
      <div style={{ margin: "11px 0 0 0", whiteSpace: "nowrap", }}>Services disponibles :</div>
      <div>
        {line.services.map((service, index) => <img
          key={`${line.id}-s${index}`}
          style={{ width: 32, margin: 4, }}
          src={service.image}
          alt={service.name}
        />)}
      </div>
    </div>)}

    {line.products && (<div style={{ margin: "10px 0 0 0", display: "flex" }}>
      <div style={{ margin: "11px 0 0 0", whiteSpace: "nowrap" }}>Produits en attente :</div>
      <div>
        {line.products.map((products, index) => <img
          key={`${line.id}-p${index}`}
          style={{ width: 32, margin: 4 }}
          src={products.image}
          alt={products.name}
        />)}
      </div>
    </div>)}

    <input className="dropin-marker-id" type="hidden" value={line.id}/>

    {printMode && <hr/>}
  </div>
}

const MARKERS_LABELS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"

interface Props {
  apiKey: string
  defineActionOnPrint?: (action: () => void) => void
  getCurrentInteractiveMap: () => GoogleMap
  style: GoogleMapsDocumentOptionsStyle[]
}

export default class GoogleMapsPrintable extends WebComponent<Props> {
  state = { list: [] }

  componentDidMount() {
    this.props.defineActionOnPrint(() => {
      const map = this.props.getCurrentInteractiveMap()
      if(map === null) {
        alert("Cette opération n'est pas disponible pour le moment")
      } else if(linesStore.items.length === 0) {
        alert("Vous devez au moins avoir un élément sur la carte pour pouvoir l'imprimer")
      } else {
        const list = linesStore.items.filter((line: any) => map.getBounds().contains(line.gps))
        if(list.length > MARKERS_LABELS.length) {
          alert(
            `Vous ne pouvez pas imprimer plus de ${MARKERS_LABELS.length} éléments, ` +
            `${list.length - MARKERS_LABELS.length} sont en trop`
          )
        } else {
          this.setState({ list })
        }
      }
    })
  }

  render() {
    if(this.state.list.length === 0) return null

    // Google Maps URL
    const size = `${window.innerWidth}x${window.innerHeight}`
    let url = `https://maps.googleapis.com/maps/api/staticmap?key=${this.props.apiKey}&size=${size}`

    // Adding markers
    this.state.list.forEach((line: any, index) => {
      url += "&markers=color:blue"
        + `|label:${MARKERS_LABELS[index]}`
        + `|${line.gps.lat},${line.gps.lng}`
    })

    // Style
    if(typeof this.props.style !== "undefined") {
      this.props.style.forEach(styleObject => {
        url += `&style=`
        if(typeof styleObject.featureType !== "undefined") {
          url += `feature:${styleObject.featureType}|`
        }
        if(typeof styleObject.elementType !== "undefined") {
          url += `element:${styleObject.elementType}|`
        }
        url += styleObject.stylers.map(s => Object.keys(s).map(p => {
          return `${p}:${s[p].replace("#", "0x")}`
        }).join("|")).join("|")
      })
    }

    // Generate the static image then ask to print it
    return <div id="printable-map">
      <img
        style={{ width: "100%", }}
        src={encodeURI(url)}
        onLoad={() => {
          //if (document.getElementById("printable-map").querySelector("img").complete) {
          window.print();
          /* setTimeout(() => {
            this.setState({ list: [] });
          }, 2000) */
          //}
        }}
      />
      <hr/>
      {this.state.list.map((line, index) => hardcodedLaClocheHtml(line, true, MARKERS_LABELS[index]))}
    </div>
  }

}
